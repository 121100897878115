import React from 'react';
import './HeroSection.css';


const HeroSection = () => {
  return (
    <section id ="hero" className="hero-section">
  <h1 className="hero-title">Welcome to</h1>
  <img src={require('../../assets/fulllogo.png')} alt="Soft-Where Logo" className="hero-logo" />
  <p className="quote-text">"Turning your dreams into digital reality."</p>
  <p className="hero-subtitle">Your local solution for software and web design.</p>
  <a href="#about" className="hero-button">Learn More</a>
</section>

  );
};

export default HeroSection;
