import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faSearch, faMobileAlt, faGamepad, faPalette, faTools } from '@fortawesome/free-solid-svg-icons';
import './Services.css';

const Services = () => {
  return (
    <section id="services" className="services-section">
      <h2 className="services-heading">Our Services</h2>
      <p className="services-intro-one">
        Your software solution is unique to you and has to work every time. We can do that, in a variety of ways:
      </p>
      <div className="services-grid">
        <div className="service-card">
          <FontAwesomeIcon icon={faLaptopCode} className="service-icon" />
          <h3 className="card-title">SaaS & Custom Software Solutions</h3>
          <p className="card-description">
            Tailored software and SaaS applications designed for operational efficiency and scalability.
          </p>
          
          {/*
          <p className="service-pricing">
            <strong>Packages:</strong><br />
            Starter: $5,000 – $10,000<br />
            Standard: $10,000 – $25,000<br />
            Premium: $25,000+<br />
            <strong>Maintenance:</strong> Starting at $300/month<br />
            <strong>SaaS Licensing:</strong><br />
            Basic: $300/month<br />
            Professional: $700/month<br />
            Enterprise: $1,500/month
          </p>
          */}
        </div>
        
        <div className="service-card">
          <FontAwesomeIcon icon={faMobileAlt} className="service-icon" />
          <h3 className="card-title">Mobile App Development</h3>
          <p className="card-description">
            Engaging mobile apps for iOS and Android, tailored to meet user and business needs.
          </p>
          
          {/*
          <p className="service-pricing">
            <strong>Packages:</strong><br />
            Basic: $5,000 – $10,000<br />
            Standard: $10,000 – $20,000<br />
            Advanced: $20,000+<br />
            <strong>Maintenance:</strong> $300 – $800/month
          </p>
          */}
        </div>
        
        <div className="service-card">
          <FontAwesomeIcon icon={faPalette} className="service-icon" />
          <h3 className="card-title">UI/UX Design</h3>
          <p className="card-description">
            Visually appealing and user-friendly interfaces for web and mobile applications.
          </p>
          
          {/*
          <p className="service-pricing">
            <strong>Packages:</strong><br />
            Single Page: $1,000 – $3,000<br />
            Small Website/App: $3,000 – $8,000<br />
            Comprehensive: $8,000+<br />
            <strong>Retainer:</strong> $500 – $1,500/month
          </p>
          */}
        </div>
        
        <div className="service-card">
          <FontAwesomeIcon icon={faGamepad} className="service-icon" />
          <h3 className="card-title">Video Game Development</h3>
          <p className="card-description">
            From concept to launch, we create engaging, feature-rich games for all platforms.
          </p>
          
          {/*
          <p className="service-pricing">
            <strong>Packages:</strong><br />
            Simple Game: $10,000 – $20,000<br />
            Standard 2D Game: $20,000 – $40,000<br />
            Advanced 3D Game: $50,000+<br />
            <strong>Maintenance:</strong> $1,000 – $4,000/month
          </p>
          */}
        </div>
        
        <div className="service-card">
          <FontAwesomeIcon icon={faTools} className="service-icon" />
          <h3 className="card-title">Hosting, Maintenance & Support</h3>
          <p className="card-description">
            Reliable hosting with continuous maintenance to ensure peak performance.
          </p>
          
          {/*
          <p className="service-pricing">
            <strong>Hosting:</strong><br />
            Basic: $100 – $300/month<br />
            Standard: $300 – $800/month<br />
            Premium: $1,200/month
          </p>
          */}
        </div>
        
        <div className="service-card">
          <FontAwesomeIcon icon={faSearch} className="service-icon" />
          <h3 className="card-title">SEO & Digital Marketing</h3>
          <p className="card-description">
            Data-driven strategies to increase visibility, traffic, and conversions.
          </p>
          {/*
          <p className="service-pricing">
            <strong>Monthly Packages:</strong><br />
            Basic: $300/month<br />
            Growth: $800 – $1,500/month<br />
            Advanced: $2,000+
          </p>
          */}
        </div>
      </div>
    </section>
  );
};

export default Services;