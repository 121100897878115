import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
  const [form, setForm] = useState({
    from_name: '',
    reply_to: '',
    message: ''
  });

  // Accessing environment variables
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      serviceId,
      templateId,
      {
        from_name: form.from_name,
        to_name: 'Soft-Where Team',
        message: form.message,
        reply_to: form.reply_to
      },
      userId
    )
    .then((response) => {
      alert('Message sent successfully!');
      setForm({ from_name: '', reply_to: '', message: '' });
    })
    .catch((err) => {
      alert('Failed to send message. Please try again.');
    });
  };

  return (
    <section id="contact" className="contact-section">
      <div className="contact-container">
        <h2 className="contact-heading">Contact Us</h2>
        <div className="contact-text">
          <h3>
            Please contact the SOFT-WHERE team with any questions regarding product, prices, timeline, 
            or any other inquiries you may have. Once the message is sent, a Soft-Where team representative 
            will respond as soon as possible.
          </h3>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="from_name"
            placeholder="Your Name"
            value={form.from_name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="reply_to"
            placeholder="Your Email"
            value={form.reply_to}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={form.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
