import React from 'react';
import './About.css'; // Import the CSS file for styling

const About = () => {
  return (
    <section id="about" className="about-section"> {/* Background and overlay styling in CSS */}
      <h2 className="about-heading">About Us</h2> {/* Matches .about-heading styles */}
      <div className="about-content"> {/* Matches .about-content styles */}
        <div className="about-point"> {/* Matches .about-point styles */}
          <h3>Small, Locally-Run Company</h3>
          <p>
            Based in Minot, North Dakota, we are dedicated to serving local businesses and clients beyond our community with tailored digital solutions.
          </p>
        </div>
        <div className="about-point">
          <h3>Comprehensive Digital Solutions</h3>
          <p>
            From custom software development to web design, we offer a range of services that align with your brand’s vision and deliver measurable results.
          </p>
        </div>
        <div className="about-point">
          <h3>Client-Focused Approach</h3>
          <p>
            We work closely with our clients from start to finish, ensuring every solution is built to empower businesses to thrive in a digital world.
          </p>
        </div>
        <div className="about-point">
          <h3>Our Mission</h3>
          <p>
            To create software and web solutions that look great and perform seamlessly, building lasting relationships through reliable service and support.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
